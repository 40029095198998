export default {
  tooltip: {
    trigger: 'item',
    transitionDuration: 0,
    position (point, params, dom, rect, size) {
      var x = 0 // x坐标位置
      var y = 0 // y坐标位置
      // 当前鼠标位置
      var pointX = point[0]
      var pointY = point[1]
      // 提示框大小
      var boxWidth = size.contentSize[0]
      var boxHeight = size.contentSize[1]

      // boxWidth > pointX 说明鼠标左边放不下提示框
      if (boxWidth > pointX) {
        x = pointX + 10
      } else {
        // 左边放的下
        x = pointX - boxWidth - 10
      }

      // boxHeight > pointY 说明鼠标上边放不下提示框
      if (boxHeight > pointY) {
        y = 5
      } else {
        // 上边放得下
        y = pointY - boxHeight
      }
      return [x, y]
    }
  },
  legend: {
    top: 'center',
    left: '57%',
    orient: 'vertical',
    itemGap: 12,
    itemWidth: 14,
    itemHeight: 10
  },
  series: [
    {
      // name: 'Access From',
      type: 'pie',
      radius: ['40%', '60%'],
      center: ['32%', '50%'],
      avoidLabelOverlap: true,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff', // 背景色
        borderWidth: 10
      },
      label: {
        // 饼图中间文字设置

        normal: {
          show: true,
          position: 'center',
          color: '#000',
          formatter: `总收益\n0`,
          fontSize: '14',
          fontWeight: 300,
          lineHeight: 20
        },
        emphasis: {
          // 中间文字显示
          show: true
        }
      },
      emphasis: {
        label: {
          show: false,
          fontSize: '40',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: true
      },

      data: [
        // {
        //   value: 1048,
        //   name: '',
        //   itemStyle: {
        //     color: '#7ACDED'
        //   }
        // },
        // {
        //   value: 735,
        //   name: '',
        //   itemStyle: {
        //     color: '#EF6666'
        //   }
        // },
        // {
        //   value: 580,
        //   name: '',
        //   itemStyle: {
        //     color: '#FAC858'
        //   }
        // },
        // {
        //   value: 484,
        //   name: '',
        //   itemStyle: {
        //     color: '#91CC75'
        //   }
        // },
        {
          value: 300,
          name: '',
          itemStyle: {
            color: '#5470C6'
          }
        }
      ]
    }
  ]
}
